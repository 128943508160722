import { Input as AntdInput, Form } from "antd";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import "./Float.css";

const FloatInput = ({
  label,
  name,
  required = false,
  type = "text",
  placeholder,
  onChange,
  prefix,
  disabled = false,
  ...rest
}) => {
  const { setFieldTouched } = useFormikContext();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Form.Item name={name} onChange={onChange}>
      <Field name={name}>
        {({ field, meta }) => (
          <>
            {type === "text" ? (
              <>
                <AntdInput
                  {...field}
                  {...rest}
                  prefix={prefix}
                  disabled={disabled}
                  onFocus={() => {
                    setIsFocused(true);
                    setFieldTouched(name, true);
                  }}
                  onBlur={() => setIsFocused(false)}
                  style={{
                    borderColor: meta.error && (meta.touched || disabled) ? "red" : "",
                  }}
                />
                <div
                  className={`input-label ${isFocused || field.value ? "input-label-focused" : ""
                    } ${meta.error && (meta.touched || disabled) ? "input-label-error" : ""}`}
                  style={{
                    fontWeight: "500",
                    color: meta.error && (meta.touched || disabled) ? "red" : "",
                    marginLeft: prefix ? "25px" : "8px",
                    zIndex: 1,
                  }}
                >
                  {required ? (
                    <>
                      <span className="required-asterisk">*</span>{" "}
                      {label ? label : placeholder}
                    </>
                  ) : label ? (
                    label
                  ) : (
                    placeholder
                  )}
                </div>
              </>
            ) : (
              <>
                <AntdInput.TextArea
                  {...field}
                  {...rest}
                  disabled={disabled}
                  onFocus={() => {
                    setIsFocused(true);
                    setFieldTouched(name, true);
                  }}
                  onBlur={() => setIsFocused(false)}
                  style={{
                    borderColor: meta.error && (meta.touched || disabled) ? "red" : "",
                  }}
                />
                <div
                  className={`input-label ${isFocused || field.value ? "input-label-focused" : ""
                    } ${meta.error && (meta.touched || disabled) ? "input-label-error" : ""}`}
                  style={{
                    fontWeight: "500",
                    color: meta.error && (meta.touched || disabled) ? "red" : "",
                  }}
                >
                  {required ? (
                    <>
                      <span className="required-asterisk">*</span>{" "}
                      {label ? label : placeholder}
                    </>
                  ) : label ? (
                    label
                  ) : (
                    placeholder
                  )}
                </div>
              </>
            )}
            {/* Display error message when the field is disabled and has an error */}
            {(meta.error && (meta.touched || disabled)) && (
              <div className="ant-form-item-explain-error">
                {meta.error}
              </div>
            )}
          </>
        )}
      </Field>
    </Form.Item>
  );
};

FloatInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

FloatInput.defaultProps = {
  label: "",
  name: "",
  required: false,
  type: "text",
  placeholder: "",
  disabled: false,
};

export default FloatInput;
