import { put, takeLatest } from "redux-saga/effects";
import {
  requestManageSubscriberList,
  requestCreateSubscriber,
  requestUpdateSubscriber,
  requestSubscriberDataById,
  requestSubscriberDataGetHeaderImages,
} from "services/manageSubscriber";
import {
  fetchManageSubscriberRequest,
  fetchManageSubscriberSuccess,
  fetchManageSubscriberFailure,
  createManageSubscriberRequest,
  createManageSubscriberSuccess,
  createManageSubscriberFailure,
  updateManageSubscriberRequest,
  updateManageSubscriberSuccess,
  updateManageSubscriberFailure,
  getSubscriberDetailsRequest,
  getSubscriberDetailsSuccess,
  getSubscriberDetailsFailure,
  getSubscriberDetailsHeaderImagesRequest,
  getSubscriberDetailsHeaderImagesSuccess,
  getSubscriberDetailsHeaderImagesFailure,
} from "redux/reducers/manageSubscriber";

export function* watchManageSubscribersSaga() {
  yield takeLatest(fetchManageSubscriberRequest, fetchSubscribersData);
  yield takeLatest(createManageSubscriberRequest, createSubscribers);
  yield takeLatest(updateManageSubscriberRequest, updateSubscribers);
  yield takeLatest(getSubscriberDetailsRequest, getSubscribersGetByIdData);
  yield takeLatest(
    getSubscriberDetailsHeaderImagesRequest,
    getSubscribersGetHeaderImagesData
  );
}

function* fetchSubscribersData({ payload }) {
  try {
    const response = yield requestManageSubscriberList(payload);
    yield put(fetchManageSubscriberSuccess(response));
  } catch (error) {
    yield put(fetchManageSubscriberFailure("Failed to get list of Subscribers"));
  }
}

function* createSubscribers({ payload }) {
  try {
    const response = yield requestCreateSubscriber(payload);
    yield put(createManageSubscriberSuccess(response.data));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message || "Failed to create Subscriber";
    yield put(createManageSubscriberFailure(message));
  }
}

function* updateSubscribers({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSubscriber(payload);
    yield put(updateManageSubscriberSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update Subscriber";
    yield put(updateManageSubscriberFailure(message));
  }
}

function* getSubscribersGetByIdData({ payload }) {
  try {
    const response = yield requestSubscriberDataById(payload);
    yield put(getSubscriberDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get Subscribers details";
    yield put(getSubscriberDetailsFailure(message));
  }
}

function* getSubscribersGetHeaderImagesData({ payload }) {
  try {
    const response = yield requestSubscriberDataGetHeaderImages(payload);
    yield put(getSubscriberDetailsHeaderImagesSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get Subscribers details";
    yield put(getSubscriberDetailsHeaderImagesFailure(message));
  }
}
